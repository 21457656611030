import styled from 'styled-components'
import { theme, media } from './../../../helpers/theme'
import Link from 'gatsby-link'

const LinkGrid = styled.div`
  background-color: ${theme.colors.background};
  margin: ${theme.space[10]}px auto;
`

const Container = styled.div`
  display: flex;
  padding: ${theme.space[5]}px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  min-height: 175px;

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

const PageLink = styled(Link)`
  transition: all 0.2s ease-in-out;
  color: ${(props) =>
    props.pathname === props.to ? theme.colors.black : theme.colors.font.green};
  font-size: ${theme.font.base};
  font-weight: 400;
  text-decoration: none;
  flex: 1;
  flex-basis: 50%;
  padding: ${theme.space[3]}px;

  ${media('sm')} {
    flex-basis: 25%;
  }

  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.darkGreen};
  }
`

LinkGrid.container = Container
LinkGrid.link = PageLink
export default LinkGrid
