import React from 'react'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import LinkGridLayout from './link-grid.styles'
import { TERMS_INFO } from '../../../helpers/terms.constants'

const LinkGrid = ({ pathname }) => (
  <LinkGridLayout>
    <LinkGridLayout.container>
      <LinkGridLayout.link
        pathname={pathname}
        data-element-location={DataElementLocations.BODY}
        data-element-label='terms.link-grid.link'
        data-element-id={'sitey-terms-link-grid-index'}
        to={'/terms'}
      >
        Terms Index
      </LinkGridLayout.link>
      {TERMS_INFO.map((info) => (
        <LinkGridLayout.link
          pathname={pathname}
          key={info.id}
          data-element-location={DataElementLocations.BODY}
          data-element-label='terms.link-grid.link'
          data-element-id={`sitey-terms-link-grid-${info.id}`}
          to={
            info.id === 'privacy-notice'
              ? 'https://newfold.com/privacy-center '
              : `/terms/${info.id}`
          }
        >
          {info.title}
        </LinkGridLayout.link>
      ))}
      <LinkGridLayout.link pathname={pathname} />
    </LinkGridLayout.container>
  </LinkGridLayout>
)

export default LinkGrid
