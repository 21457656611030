export const TERMS_INFO = [
  {
    title: 'Terms of Service',
    id: 'terms-of-service',
    description: 'These are the terms you agree to when you use Sitey.',
  },
  {
    title: 'Privacy Notice',
    linkOption: '_blank',
    id: 'privacy-notice',
    description:
      'This Privacy Notice applies to the personal data of users collected or used by Endurance International Group, Inc, doing business as Sitey.',
  },
  {
    title: 'Acceptable Use',
    id: 'acceptable-use',
    description:
      "This Acceptable Use Policy ('AUP') governs your use of the Services and is incorporated by reference into Sitey's Terms and Conditions.",
  },
  {
    title: 'Trademark Disputes',
    id: 'trademark-disputes',
    description: 'Sitey Trademark Disputes for Domain Names.',
  },
  {
    title: 'Domain Registration',
    id: 'domain-registration',
    description: 'Sitey Domain Registration Agreement.',
  },
  {
    title: 'Copyright',
    id: 'copyright',
    description: 'Copyright Infringement Policy.',
  },
  {
    title: 'DNRN Policy',
    id: 'dnrn-policy',
    description: 'Sitey Domain Name Renewal Notification Policy.',
  },
  {
    title: 'Data Request Policy',
    id: 'data-request-policy',
    description: 'Sitey Data Request Policy.',
  },
  {
    title: 'Arbitration Agreement',
    id: 'arbitration-agreement',
    description: 'Sitey Arbitration Agreement.',
  },
  {
    title: 'Professional Services Agreement',
    id: 'professional-services-agreement',
    description: 'Sitey Professional Services Agreement.',
  },
]
